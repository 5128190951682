.App {
    max-width: 450px;
    width: 100%;
    margin: auto;
    border: 1px solid #ccc;
}
.input-with-format::before {
    content: attr(data-value); /* Hiển thị giá trị đã định dạng từ data-value */
}
.ant-input-number-input:focus {
    border: none !important;
    box-shadow: none !important;
}
.ant-input-number-input:active {
    border: none !important;
    box-shadow: none !important;
}
.bank-list option {
    font-size: 12px;
    max-width: 375px;
    width: 100%;
}
